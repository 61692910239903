<script>
import CandidatureCard from '@/components/general/CandidatureCard'
import ModalMessages from '@/components/general/ModalMessages'
export default {
  components: { CandidatureCard, ModalMessages },
  name: 'IncompletedCandidature',
  props: {
    incompletePositions: {
      type: Object
    }
  },
  data () {
    return {
      company: {},
      position: {},
      showModal: false,
      getPositonsIncomplete: [],
      isLoading: true
    }
  },
  methods: {
    handleShowModalMessages (item) {
      this.company = { name: item.company.name, image: item.company.logo }
      this.position = { name: item.title, id: item.id }
      this.showModal = !this.showModal
    }
  },
  created () {
    this.$store.dispatch('attemptGetAllPositions', this.selectedWorkspace.name.toLowerCase()).then((data) => {
      const positions = []
      for (const item of data) {
        positions.push(item.position)
      }
      this.getPositonsIncomplete = positions.filter(d => d.status !== 'no_filter' && d.company.name.toLowerCase() === this.selectedWorkspace.name.toLowerCase()).sort((a, b) => a.title.localeCompare(b.title))
      this.isLoading = false
    })
  }
}
</script>
<template>
  <div class="incompleted-candidature--container">
    <p>{{ $t('internal.mobility:incompleted.candidature.description') }}</p>

    <div class="incompleted-candidature--list" >
      <div class="skeleton-incomplete" v-if="isLoading">
        <v-skeleton-loader class="skeleton-card-incomplete max-width" height="80px" type="list-item-avatar-two-line" v-for="i in ['a', 'b', 'c']"
          :key="i" />
      </div>
      <candidature-card v-for="item in this.getPositonsIncomplete" v-else
        @handleModalMessage="handleShowModalMessages(item)" :key="item.id" :candidature="{
          position: {
            company: {
              logo: item.company.logo,
              name: item.company.name,
              url: item.positionUrl
            },
            title: item.title,
            status: item.status,
          },
          stepsLeft: 1,
        }">
      </candidature-card>
    </div>
    <modal-messages v-if="showModal" :company=company :position=position @close="showModal = false"></modal-messages>
  </div>
</template>
<style lang="scss">
.incompleted-candidature--container {
  .incompleted-candidature--list {
    .skeleton-incomplete {
      clear: both;
      display: flex;
      flex-direction: column;

      .skeleton-card-incomplete {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
